/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'remark',
    itemProps: {
      label: '用途说明：',
      rules: [
      genRules('require')] },


    component: {
      name: 'el-input',
      props: {
        filterable: true,
        type: 'textarea',
        showWordLimit: true,
        maxlength: 100 } } },



  {
    span: 10,
    kname: 'name',
    itemProps: {
      label: '借用人：',
      rules: [
      genRules('require')] } }];});






// form 默认值
export var formData = function formData() {return { list: [] };};