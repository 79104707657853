var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "v-dialog"
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.onClose
          }
        },
        [
          _c(
            "c-box",
            { attrs: { padding: "10px 40px" } },
            [
              _c(
                "c-box",
                {
                  attrs: {
                    flex: "row",
                    align: "center",
                    padding: "10px",
                    justify: "flex-start"
                  }
                },
                [
                  _c("c-box", { attrs: { width: "160px" } }, [
                    _vm._v("盘点任务位置：A2")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", size: "small" },
                      on: { click: _vm.download }
                    },
                    [_vm._v("下载模板")]
                  )
                ],
                1
              ),
              _c(
                "c-box",
                {
                  attrs: {
                    flex: "row",
                    align: "center",
                    padding: "10px",
                    justify: "flex-start"
                  }
                },
                [
                  _c("c-box", { attrs: { width: "160px" } }, [
                    _vm._v("盘点结果导入：")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", size: "small" },
                      on: { click: _vm.upload }
                    },
                    [_vm._v("导入")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }